<template>
  <div class="app-container">
    <eHeader :query="query" @toQuery="toQuery"/>

    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="文件编号"/>
      <el-table-column prop="fileName" label="文件名称" width="280px"/>
      <el-table-column label="对像类型">
        <template slot-scope="scope">
          <span>{{ scope.row.objectType=='agent' ? '代理':'个人' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="objectNo" label="对像编号"/>
      <el-table-column prop="fileType" label="文件类型"/>
      <el-table-column prop="fileSize" label="文件大小"/>
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','DIMS_ALL','DIMS_VIEW'])" :data="scope.row" :sup_this="sup_this"/>
        </template>
      </el-table-column>
    </el-table>

     <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>

  </div>
</template>

<script>
import eHeader from '@/components/tools/dims/header'
import initData from '@/mixins/initData'
import checkPermission from '@/utils/permission'
import { parseTime } from '@/utils/index'
import edit from '@/components/tools/dims/edit'
export default {
  components: {
    eHeader,
    edit,
  },
  mixins: [initData],
  data() {
    return {
      sup_this: this,
      formData:{}
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "crm/file/list";
      const sort = "id,desc";
      this.params = { page: this.page, size: this.size, sort: sort };
      const query = this.query
      const objectType = query.objectType
      const objectNo = query.objectNo
      const fileType = query.fileType
      const fileName = query.fileName
      if (objectType !== '' && objectType !== null) { this.params['objectType'] = objectType }
      if (objectNo !== '' && objectNo !== null) { this.params['objectNo'] = objectNo }
      if (fileType !== '' && fileType !== null) { this.params['fileType'] = fileType }
      if (fileName !== '' && fileName !== null) { this.params['fileName'] = fileName }
      return true
    },
  }
}
</script>

<style scoped>

</style>
