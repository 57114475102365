<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            v-model="query.objectType"
            clearable
            placeholder="对象类型"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option v-for="item in ObjectTypeOptions" :key="item.key" :label="item.display_name" :value="item.key" />
    </el-select>
    <el-select v-model="query.fileType" clearable placeholder="文件类型" class="filter-item" style="width: 130px" @change="toQuery">
      <el-option v-for="item in fileTypeOptions" :key="item.key" :label="item.display_name" :value="item.key" />
    </el-select>
    <el-input v-model="query.objectNo" clearable placeholder="对象编号" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.fileName" clearable placeholder="文件名称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
// 查询条件
export default {
  props: {
    query: {
      type:Object,
      required: true
    }
  },
  data() {
    return {
      ObjectTypeOptions: [
        { key: 'agent', display_name: '代理' },
        { key: 'person', display_name: '个人' }
      ],
      fileTypeOptions: [
        { key: '.jpg', display_name: '.jpg' },
        { key: '.png', display_name: '.png' },
        { key: '.gif', display_name: '.gif' },
        { key: '.docx', display_name: '.docx' },
        { key: '.xls', display_name: '.xls' },
        { key: '.xlsx', display_name: '.xlsx' },
        { key: '.ppt', display_name: '.ppt' },
        { key: '.mp3', display_name: '.mp3' },
        { key: '.mp4', display_name: '.mp4' }
      ]
    }
  },
  methods: {
    // 去查询
    toQuery() {
      this.$emit('toQuery');
    },
  }
}
</script>
