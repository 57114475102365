<template>
  <div>
    <el-button size="mini" type="success" @click="to">查看</el-button>
  </div>
</template>
<script>

export default {
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      fileName:"",
      herfUrl:"",
    }
  },
  methods: {
    to() {
      window.open(this.data.herfUrl, '_blank');
    },
  }
}
</script>

<style scoped>
</style>
